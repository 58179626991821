<script src="./Footer.ts"></script>

<template lang="en">
  <div class="Contact vh-25" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1>Afspraak maken?</h1>
        </div>
        <div class="col-md-6">
          <h3><font-awesome-icon :icon="['fab', 'whatsapp']" /> +31 6 213 40 650</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2>Stuur mij een bericht.</h2>
        </div>
        <div class="col-md-6">
          <h3><font-awesome-icon icon="envelope" /> <a href="mailto:info@bobinstallaties.nl">info@bobinstallaties.nl</a></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
        </div>
        <div class="col-md-6">
          <h3><font-awesome-icon icon="map-marker-alt" /> Regio: Roosendaal</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="Footer vh-50 padding-top-50">
    <img id="footer-image" src="../../assets/Logo.svg" alt="footer-image">
    <h2>KVK: <span>84071443</span></h2>
  </div>
</template>

<style lang="scss" src="./Footer.scss" scoped></style>
