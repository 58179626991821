<script src="./Activities.ts"></script>

<template lang="en">
    <div class="vh-50 padding-top-bottom-75" id="werkzaamheden">
        <div class="container">    
            <div class="row" >
                <div class="col-md-12">
                    <h1>Werkzaamheden</h1>
                </div>
            </div>
            <div class="row" >
                <div class="col-md-12">
                    <table class="activities_table">
                        <tr>
                            <td v-for="(item, index) in data" class="center">
                                <div>
                                    <h2>{{item.image_alt}}</h2>
                                </div>
                                <div class="image_container">
                                    <div class="activities_image" v-bind:style="{ 'background-image': 'url(' + getImageUrl(item.image) + ')'}"></div> 
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</template>

<style lang="scss" src="./Activities.scss" scoped></style>
