<script src="./Carousel.ts"></script>

<template lang="en">
    <div :id="name" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button v-for="(item, index) in data" type="button" :data-bs-target="'#'+name" v-bind:class="getActiveClassButton(index)" v-bind:aria-current="getAriaCurrent(index)" v-bind:data-bs-slide-to="index" v-bind:aria-label="getAriaLabel('Slide', index)" ></button>
        </div>
        <div class="carousel-inner">
            <div v-for="(item, index) in data" v-bind:class="getActiveClassDiv(index)" v-bind:style="{ 'background-image': 'url(' + getImageUrl(item.image) + ')'}">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="'#'+name" data-bs-slide="prev">
            <span class="prev-icon" aria-hidden="true"><font-awesome-icon icon="chevron-left" /></span>
            <span class="visually-hidden">Vorige</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="'#'+name" data-bs-slide="next">            
            <span class="next-icon" aria-hidden="true"><font-awesome-icon icon="chevron-right" /></span>
            <span class="visually-hidden">Volgende</span>
        </button>
    </div>
</template>

<style lang="scss" src="./Carousel.scss" scoped></style>
