import * as Bootstrap from 'bootstrap'
import { defineComponent } from 'vue'

export default defineComponent({
    props:['data', 'name'],
    methods: {
        getImageUrl,
        getAriaLabel,
        getActiveClassButton,
        getActiveClassDiv,
        getAriaCurrent
    },
    mounted() {
        new Bootstrap.Carousel('carousel');
    }
})

export function getImageUrl(image: string): string {
    return require("@/assets/" + image);
}

export function getAriaLabel(text: string, index: number): string {
    return text + " " + index;
}

export function getActiveClassButton(index: number): string {
    return index == 0 ? "active" : "";
}

export function getActiveClassDiv(index: number): string {
    return index == 0 ? "carousel-item active" : "carousel-item";
}

export function getAriaCurrent(index: number): string {
    return index == 0 ? "true" : "false";
}