import { defineComponent } from 'vue'
import json from '@/assets/content.json'
import Carousel from '../Carousel/Carousel.vue'

export default defineComponent({
    data: function() {
        return json
    },
    methods:{
        getImageUrl
    },
    components:{
        Carousel
    }
})

export function getImageUrl(image:string): string {
    return require("@/assets/"+image);
}
