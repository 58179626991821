import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEnvelope,faMapMarkerAlt, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faFontAwesome, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faEnvelope, faFontAwesome, faWhatsapp, faMapMarkerAlt, faChevronLeft, faChevronRight)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon )
    .use(router)
    .mount('#app')
