<script src="./TopNavBar.ts"></script>

<template lang="en">
    <nav class="navbar navbar-expand-lg fixed-top navbar-light pxh-50 bg-white">
        <a class="navbar-brand companyname"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown" ref="collapse">
            <ul class="navbar-nav ms-auto flex-nowrap">
                <li class="nav-item">
                    <router-link to="/#introduction" class="nav-link" @click="closeMenu()" >
                        Over Bob
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/#werkzaamheden" class="nav-link" @click="closeMenu()">
                        Werkzaamheden
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/#contact" class="nav-link" @click="closeMenu()">
                        Contact
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<style lang="scss" src="./TopNavBar.scss" scoped></style>
