import { defineComponent } from 'vue'
import json from '@/assets/activities.json'

export default defineComponent({
    data: function() {
        return json;
    },
    methods:{
        getImageUrl
    }
})

export function getImageUrl(image:string): string {
    return require("@/assets/"+image);
}
