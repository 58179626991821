
import Activities from '@/components/Activities/Activities.vue'
import TopNavBar from '@/components/TopNavBar/TopNavBar.vue'
import Introduction from '@/components/Introduction/Introduction.vue'
import Spotlight from '@/components/Spotlight/Spotlight.vue'
import Content from '@/components/Content/Content.vue'
import Footer from '@/components/Footer/Footer.vue'
import Location from '@/components/Location/Location.vue'
export default{
  name: 'App',
  components:{
    Activities,
    TopNavBar,
    Introduction,
    Location,
    Spotlight,
    Content,
    Footer
  }
}
