<script src="./Content.ts"></script>

<template lang="en">
  <div class="Content min-vh-100">
    <div v-for="(item, index) in data" class="vh-50 padding-top-bottom-75">
      <div class="container">
        <div class="row">
          <div class="col-md-6" v-bind:class="{'order-last': index % 2 != 0 }">
            <h1 class="content_title">{{item.title}}</h1>
            <span>{{item.text}}</span>
          </div>
          <div class="col-md-6" v-bind:class="{'order-first': index % 2 != 0 }">
            <Carousel :data="item.images" :name="item.name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./Content.scss" scoped></style>
