import { Collapse } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
    setup() {
        const collapse = ref<Element | null>(null);
        const collapsMenu = ref<Collapse | null>(null);

        function closeMenu(): void {
            collapsMenu.value?.hide();
        }
        onMounted(() => {
            Collapse.Default.toggle = false; // default toggles collapse => on pageload the control is collapsed.
            // Default toggles -> showing the collapse menu.
            // setting it to false prohibits toggle
            collapsMenu.value = new Collapse(collapse.value as Element);
        });

        return { closeMenu, collapse }
    }
})
