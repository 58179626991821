<script src="./Introduction.ts"></script>

<template lang="en">
  <div class="Introduction min-vh-100" id="introduction">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 v-html="title"></h1>
          
          <div class="row rowspace">
            <div class="col-12 color-white paragraph">
              {{ text }}
            </div>
          </div>
          <div class="row rowspace">
            <div class="col-12">
              <ul class="list-group list-group-horizontal list-group-flush">
                <li class="list-group-item transparent">
                  <router-link to="/#werkzaamheden" class="btn btn-primary rounded-pill" type="button">
                    Werkzaamheden
                  </router-link>
                </li>
                <li class="list-group-item transparent">
                  <router-link to="/#contact" class="btn btn-tertiary rounded-pill" type="button">
                    Neem contact op
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col" id="wrench">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./Introduction.scss" scoped></style>
