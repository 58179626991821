import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavBar = _resolveComponent("TopNavBar")!
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_Location = _resolveComponent("Location")!
  const _component_Spotlight = _resolveComponent("Spotlight")!
  const _component_Activities = _resolveComponent("Activities")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_TopNavBar)
      ]),
      _: 1
    })),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_Introduction)
      ]),
      _: 1
    })),
    _createVNode(_component_Location),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_Spotlight)
      ]),
      _: 1
    })),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_Activities)
      ]),
      _: 1
    })),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_Content)
      ]),
      _: 1
    })),
    _createVNode(_component_Footer)
  ]))
}