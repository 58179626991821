import Content from '@/components/Content/Content'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Content
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    }
  },
  history: createWebHashHistory(),
  routes
})

export default router
