<script src="./Spotlight.ts"></script>

<template lang="en">
    <div class="vh-50">
      <div class="container">
        <div class="row mt-5 mb-5">
          <div class="col-sm-12">
            <Carousel :data="data" :name="'spotlightCarousel'" />
          </div>
        </div>
      </div>
    </div>  
</template>

<style lang="scss" src="./Spotlight.scss" scoped></style>
