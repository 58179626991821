import { defineComponent } from 'vue'
import json from '@/assets/spotlight.json'
import Carousel from '../Carousel/Carousel.vue';

export default defineComponent({
    data: function () {
        return json;
    },
    components:{
        Carousel
    }
})