<script src="./Location.ts"></script>

<template lang="en">
  <div class="Location vh-25" id="location">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1>Regio</h1>
        </div>
        <div class="col-md-6" id="locations-list">
          <h3>Roosendaal</h3>
          <div class="h5-list">
            <h5>Bergen op Zoom</h5>|
            <h5>Etten-Leur</h5>|
            <h5>Oudenbosch</h5>|
            <h5>Oud-Gastel</h5>|
            <h5>Hoeven</h5>|
            <h5>Standdaarbuiten</h5>|
            <h5>Stampersgat</h5>|
            <h5>Bosschenhoofd</h5>|
            <h5>Heerle</h5>|
            <h5>Sint Willebrord</h5>|
            <h5>Rucphen</h5>|
            <h5>Wouw</h5>|
            <h5>Wouwse Plantage</h5>|
            <h5>Nispen</h5>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<style lang="scss" src="./Location.scss" scoped></style>
